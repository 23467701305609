import type { Context } from '@nuxt/types'
import { getInitializedZerauth, getZerauth, sessionInfo } from '~/lib/zerauth'
import { init as crispInit, updateUser as updateUserCrisp } from 'nuag-core-utils/crisp'
import { logError, logWarningWithException } from 'nuag-core-utils/logging'

const pbFull = document.getElementById('pb-full')
const loadingContainer = document.getElementById('initial-loading')

function force50percent() {
  if (pbFull) {
    pbFull.style.width = '50%'
  }
}

function force100percent(): Promise<void> {
  if (!(pbFull && loadingContainer)) {
    return Promise.resolve()
  }

  pbFull.style.width = '100%'
  loadingContainer.style.opacity = '0'
  return new Promise((resolve) => setTimeout(resolve, 600))
}

function extractFlag(context: Context, flag: string): boolean {
  if (!Array.isArray(context.route.meta)) {
    return false
  }
  return context.route.meta.some((m) => m && m[flag])
}

export default async function (context: Context) {
  try {
    try {
      crispInit()
    } catch {
      /* Ignore everything, we must not crash here for this */
    }

    const zerauth = await initialize(context)

    if (sessionInfo.me) {
      updateUserCrisp(sessionInfo.me)
      context.$sentry?.setUser({
        id: sessionInfo.me.id,
      })
    }

    const ignoreAuth = extractFlag(context, 'ignoreAuth')
    if (ignoreAuth) {
      return
    }

    const isPublic = extractFlag(context, 'public')

    if (isPublic && zerauth?.loggedIn) {
      context.redirect({
        name: sessionInfo.me!.organizations.length > 0 ? 'organizer' : 'participant',
      })
    } else if (!isPublic && !zerauth?.loggedIn) {
      context.redirect({ name: 'welcome' })
    }
  } catch (ex) {
    logWarningWithException('Zerauth', ex, 'Failed to initialize Zerauth')
    context.error({
      statusCode: 1998,
    })
  }
}

async function initialize(context: Context) {
  let zerauth = getZerauth()
  if (zerauth) {
    return zerauth
  }

  try {
    force50percent()
    zerauth = await getInitializedZerauth()
  } catch (e) {
    logError('zerauth', e, 'Failed to initialize zerauth')
    context.error({
      statusCode: 1998,
    })
  }

  await force100percent()
  return zerauth
}
