export interface IAuthenticatedFetchContext {
  readonly sessionToken: string
  refreshSession(): Promise<void>
}

export default function (context: IAuthenticatedFetchContext): typeof window.fetch {
  const getHeaders = (init: RequestInit | undefined): RequestInit => ({
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${context.sessionToken}`,
    },
  })

  return async (input, init) => {
    const response = await fetch(input, getHeaders(init))
    // TODO: check if token lifetime is going to expire soon-ish
    if (response.status === 401) {
      await context.refreshSession()
      return fetch(input, getHeaders(init))
    }
    return response
  }
}
