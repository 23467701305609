
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { hideDefaultCrispLauncher } from 'nuag-core-utils/crisp'

@Component
export default class WelcomeLayout extends Vue {
  @Prop(String) readonly replacementLogo?: string
  @Prop(String) readonly title?: string

  get hasReplacementLogo(): boolean {
    return !!this.replacementLogo
  }

  mounted() {
    hideDefaultCrispLauncher(false)
  }
}
