// From https://stackoverflow.com/a/30106551/ by brandonscript
function b64EncodeUnicode(str: string): string {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt('0x' + p1, 16))
    )
  )
}

// From https://stackoverflow.com/a/30106551/ by brandonscript
function b64DecodeUnicode(str: string): string {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )
}

export function encodeUrlB64(toEncode: string): string {
  return b64EncodeUnicode(toEncode).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export function decodeUrlB64(toDecode: string): string {
  return b64DecodeUnicode(toDecode).replace(/-/g, '+').replace(/_/g, '/')
}

export function b64ToUrlB64(toSanitize: string): string {
  return toSanitize.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}
