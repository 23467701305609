import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  NuagDateTime: any;
};

export enum InviteeStatus {
  Joined = 'JOINED',
  JoinedOther = 'JOINED_OTHER',
  JoinedSelf = 'JOINED_SELF',
  NotJoined = 'NOT_JOINED'
}

export type MeetingInfo = {
  isHsbc: Scalars['Boolean'];
  meetingDate: Scalars['NuagDateTime'];
  meetingId: Scalars['ID'];
  meetingTitle: Scalars['String'];
  organizerId: Scalars['ID'];
  organizerName: Scalars['String'];
};

export type MeetingInfoUnion = MeetingInfo | PersonalMeetingInfo;

export type NuagPublicQuery = {
  stakeholderAlreadyHasEmail: PostalStakeholderPreCheckInfo;
};


export type NuagPublicQueryStakeholderAlreadyHasEmailArgs = {
  identifyingSecret: Scalars['String'];
};

export type PersonalMeetingInfo = {
  emailForInvitee: Scalars['String'];
  fusedAccountEmail?: Maybe<Scalars['String']>;
  hasSentMfaCode: Scalars['Boolean'];
  identifyingSecret?: Maybe<Scalars['String']>;
  inviteeAccountId?: Maybe<Scalars['ID']>;
  inviteeAccountMainEmail?: Maybe<Scalars['String']>;
  inviteeDisplayName: Scalars['String'];
  inviteeEmail?: Maybe<Scalars['String']>;
  inviteeFirstName: Scalars['String'];
  inviteeHasAccount: Scalars['Boolean'];
  inviteeLastName: Scalars['String'];
  inviteeLegalEntityName?: Maybe<Scalars['String']>;
  inviteeShouldSignOnlineAttendanceSheet: Scalars['Boolean'];
  inviteeStatus: InviteeStatus;
  inviteeType: SecretTargetType;
  isHsbc: Scalars['Boolean'];
  meetingDate: Scalars['NuagDateTime'];
  meetingId: Scalars['ID'];
  meetingTitle: Scalars['String'];
  organizerId: Scalars['ID'];
  organizerName: Scalars['String'];
  pairingEmailSentTo?: Maybe<Scalars['String']>;
};

export type PostalStakeholderPreCheckInfo = {
  alreadyHasEmail: Scalars['Boolean'];
  organizerName: Scalars['String'];
};

export type PublicMutation = {
  meetingInfo?: Maybe<MeetingInfoUnion>;
  setStakeholderCanUseAccountEmail: Scalars['Boolean'];
};


export type PublicMutationMeetingInfoArgs = {
  n1Token?: InputMaybe<Scalars['String']>;
  n2Token?: InputMaybe<Scalars['String']>;
  n3Token?: InputMaybe<Scalars['String']>;
  pairingEmail?: InputMaybe<Scalars['String']>;
  requestPairing: Scalars['Boolean'];
};


export type PublicMutationSetStakeholderCanUseAccountEmailArgs = {
  canUseAccountEmail: Scalars['Boolean'];
  identifyingSecret: Scalars['String'];
};

export enum SecretTargetType {
  CseStakeholder = 'CSE_STAKEHOLDER',
  HsbcContact = 'HSBC_CONTACT',
  Stakeholder = 'STAKEHOLDER'
}

export type MeetingInfoAndRequestPairingMutationVariables = Exact<{
  n1Token?: InputMaybe<Scalars['String']>;
  n2Token?: InputMaybe<Scalars['String']>;
  n3Token?: InputMaybe<Scalars['String']>;
  pairingEmail?: InputMaybe<Scalars['String']>;
}>;


export type MeetingInfoAndRequestPairingMutation = { meetingInfo?: { __typename: 'MeetingInfo', meetingId: string, meetingDate: any, meetingTitle: string, organizerId: string, organizerName: string } | { __typename: 'PersonalMeetingInfo', meetingId: string, meetingDate: any, meetingTitle: string, organizerId: string, organizerName: string, inviteeDisplayName: string, inviteeEmail?: string | null, inviteeFirstName: string, inviteeLastName: string, inviteeLegalEntityName?: string | null, inviteeStatus: InviteeStatus, inviteeType: SecretTargetType, inviteeHasAccount: boolean, fusedAccountEmail?: string | null, inviteeShouldSignOnlineAttendanceSheet: boolean, identifyingSecret?: string | null, hasSentMfaCode: boolean, emailForInvitee: string } | null };

export type StakeholderAlreadyHasEmailQueryVariables = Exact<{
  identifyingSecret: Scalars['String'];
}>;


export type StakeholderAlreadyHasEmailQuery = { stakeholderAlreadyHasEmail: { organizerName: string, alreadyHasEmail: boolean } };

export type SetStakeholderCanUseAccountEmailMutationVariables = Exact<{
  canUseAccountEmail: Scalars['Boolean'];
  identifyingSecret: Scalars['String'];
}>;


export type SetStakeholderCanUseAccountEmailMutation = { setStakeholderCanUseAccountEmail: boolean };


export const MeetingInfoAndRequestPairingDocument = gql`
    mutation meetingInfoAndRequestPairing($n1Token: String, $n2Token: String, $n3Token: String, $pairingEmail: String) {
  meetingInfo(
    n1Token: $n1Token
    n2Token: $n2Token
    n3Token: $n3Token
    requestPairing: true
    pairingEmail: $pairingEmail
  ) {
    __typename
    ... on MeetingInfo {
      meetingId
      meetingDate
      meetingTitle
      organizerId
      organizerName
    }
    ... on PersonalMeetingInfo {
      meetingId
      meetingDate
      meetingTitle
      organizerId
      organizerName
      inviteeDisplayName
      inviteeEmail
      inviteeFirstName
      inviteeLastName
      inviteeLegalEntityName
      inviteeStatus
      inviteeType
      inviteeHasAccount
      fusedAccountEmail
      inviteeShouldSignOnlineAttendanceSheet
      identifyingSecret
      hasSentMfaCode
      emailForInvitee
    }
  }
}
    `;
export const StakeholderAlreadyHasEmailDocument = gql`
    query stakeholderAlreadyHasEmail($identifyingSecret: String!) {
  stakeholderAlreadyHasEmail(identifyingSecret: $identifyingSecret) {
    organizerName
    alreadyHasEmail
  }
}
    `;
export const SetStakeholderCanUseAccountEmailDocument = gql`
    mutation setStakeholderCanUseAccountEmail($canUseAccountEmail: Boolean!, $identifyingSecret: String!) {
  setStakeholderCanUseAccountEmail(
    identifyingSecret: $identifyingSecret
    canUseAccountEmail: $canUseAccountEmail
  )
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    meetingInfoAndRequestPairing(variables?: MeetingInfoAndRequestPairingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeetingInfoAndRequestPairingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeetingInfoAndRequestPairingMutation>(MeetingInfoAndRequestPairingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'meetingInfoAndRequestPairing', 'mutation');
    },
    stakeholderAlreadyHasEmail(variables: StakeholderAlreadyHasEmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StakeholderAlreadyHasEmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StakeholderAlreadyHasEmailQuery>(StakeholderAlreadyHasEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stakeholderAlreadyHasEmail', 'query');
    },
    setStakeholderCanUseAccountEmail(variables: SetStakeholderCanUseAccountEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetStakeholderCanUseAccountEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetStakeholderCanUseAccountEmailMutation>(SetStakeholderCanUseAccountEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'setStakeholderCanUseAccountEmail', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;