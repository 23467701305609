import type { EncryptedKey, SessionKey, SessionKeys } from '~/lib/zerauth/key-types'
import { destroyIndexedDb, openIndexedDb } from '~/lib/indexed-db'
import { decryptKey } from '~/lib/zerauth/cryptography'
import { SecondaryKeyUsage } from '~/graphql/types/accounts'
import * as logger from 'nuag-core-utils/logging'

const DB_NAME = 'keys'
const DB_VERSION = 1
const OBJECT_STORE_NAME = 'zerauth'

const MASTER_KEY_KEY = 'MASTER_KEY'
const ACCOUNTS_KEY_KEY = 'ACCOUNTS_KEY'

interface StoredMasterKey {
  sessionKey: string
  salt: string
  version: number
  masterKey: CryptoKey
}

interface StoredAccountsKey {
  iv: ArrayBuffer
  encrypted: ArrayBuffer
}

export async function isPersisted(): Promise<boolean> {
  try {
    const store = await openIndexedDb(DB_NAME, DB_VERSION, OBJECT_STORE_NAME)
    const retrievedMasterKey = await store.get(MASTER_KEY_KEY)
    const retrievedAccountsKey = await store.get(ACCOUNTS_KEY_KEY)
    store.close()
    return !!retrievedAccountsKey && !!retrievedMasterKey
  } catch (e) {
    logger.logWarningWithException('zerauth/session-store', e, 'Failed isPersisted')
    return false
  }
}

/**
 * Retrives the encryption keys from indexDB.
 * @param session The encryption keys
 */
export async function persistSession(session: SessionKeys): Promise<void> {
  try {
    const store = await openIndexedDb(DB_NAME, DB_VERSION, OBJECT_STORE_NAME)

    // overwrite preexisting keys
    await store.remove(MASTER_KEY_KEY)
    await store.remove(ACCOUNTS_KEY_KEY)

    await store.add<StoredMasterKey>(MASTER_KEY_KEY, {
      masterKey: session.masterKey.key,
      salt: session.masterKey.saltB64,
      sessionKey: session.sessionKey.sessionKey,
      version: session.masterKey.version,
    })

    await store.add<StoredAccountsKey>(ACCOUNTS_KEY_KEY, {
      encrypted: session.encryptedSessionKey.encryptedContents,
      iv: session.encryptedSessionKey.iv,
    })

    store.close()
  } catch (e) {
    logger.logWarningWithException('zerauth/session-store', e, 'Failed persistSession')
  }
}

/**
 * Retrieves a session from indexDB.
 * Returns null if there was nothing in it, throws an error if there were corrupted values.
 */
export async function retrieveSession(): Promise<SessionKeys | null> {
  try {
    // This should be inverted, but too late now
    const store = await openIndexedDb(DB_NAME, DB_VERSION, OBJECT_STORE_NAME)
    const retrievedMasterKey = await store.get(MASTER_KEY_KEY)
    const retrievedAccountsKey = await store.get(ACCOUNTS_KEY_KEY)
    store.close()

    if (!(retrievedMasterKey && retrievedAccountsKey)) {
      return null
    }

    if (!isMasterKey(retrievedMasterKey)) {
      throw new Error('Zerauth: Unexpected master key value in store')
    }
    if (!isAccountsKey(retrievedAccountsKey)) {
      throw new Error('Zerauth: Unexpected accounts key value in store')
    }

    const masterKey = {
      key: retrievedMasterKey.masterKey,
      saltB64: retrievedMasterKey.salt,
      version: retrievedMasterKey.version,
    }

    const sessionKey: EncryptedKey<SessionKey> = {
      sessionKey: retrievedMasterKey.sessionKey,
      name: 'accounts',
      iv: retrievedAccountsKey.iv,
      encryptedContents: retrievedAccountsKey.encrypted,
      usage: SecondaryKeyUsage.Signature,
    }

    return {
      masterKey,
      sessionKey: await decryptKey(sessionKey, masterKey.key, 'sign'),
      encryptedSessionKey: sessionKey,
    }
  } catch (e) {
    logger.logWarningWithException('zerauth/session-store', e, 'Failed retrieveSession')
    return null
  }
}

export async function clearSessionStore(): Promise<void> {
  try {
    await destroyIndexedDb(DB_NAME)
    logger.logInfo('Zerauth', 'Session data cleared')
  } catch (e) {
    logger.logWarningWithException('zerauth/session-store', e, 'Failed clearSessionStore')
  }
}

// Internal stuff

function isMasterKey(v: any): v is StoredMasterKey {
  return (
    !!v &&
    typeof v === 'object' &&
    typeof v.sessionKey === 'string' &&
    v.sessionKey.length > 0 &&
    typeof v.salt === 'string' &&
    v.salt.length > 0 &&
    typeof v.version === 'number' &&
    v.version > 0 &&
    v.masterKey instanceof CryptoKey &&
    v.masterKey.usages.includes('encrypt') &&
    v.masterKey.usages.includes('decrypt')
  )
}

function isAccountsKey(v: any): v is StoredAccountsKey {
  return (
    typeof v === 'object' && v && v.iv instanceof ArrayBuffer && v.encrypted instanceof ArrayBuffer
  )
}
