import { signWithPrivateKey } from '~/lib/zerauth/cryptography'
import type { OpenKeyPair, SecondaryKey } from '~/lib/zerauth/key-types'
import { SecondaryKeyUsage } from '~/graphql/types/accounts-public'
import type { KeyName } from '~/lib/zerauth/signature'
import type { NurneKey } from '~/lib/zerauth/nurne'

type FetchSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> = (
  name: N,
  usage: U
) => Promise<OpenKeyPair<SecondaryKey<N, U>>>
export interface IKeyring {
  clearAndLoadAll(keys: OpenKeyPair<SecondaryKey>[]): void
  clear(): void
  ensureExists<N extends KeyName, U extends SecondaryKeyUsage>(
    keyName: N,
    usage: U,
    fetchSecondaryKey: FetchSecondaryKey<N, U>
  ): Promise<OpenKeyPair<SecondaryKey<N, U>>>
  signPayload(keyName: KeyName, payload: string): Promise<string>

  extractNurneKey(keyName: `nurne_${string}`): OpenKeyPair<NurneKey> | null
}

/**
 * Creates a new keyring to hold all of the user's secondary keys.
 * This is a separate type wrapped in its own scope instead of exported, so that other modules cannot tamper with
 * the keyring's content.
 */
export function createSecondaryKeyring(): IKeyring {
  let secondaryKeysCache: Record<string, OpenKeyPair<SecondaryKey>> = {}

  return {
    clearAndLoadAll(keys): void {
      secondaryKeysCache = {}
      keys.forEach((key) => {
        secondaryKeysCache[key.name] = key
      })
    },
    clear() {
      secondaryKeysCache = {}
    },
    async ensureExists<N extends KeyName, U extends SecondaryKeyUsage>(
      keyName: N,
      usage: U,
      fetchSecondaryKey: FetchSecondaryKey<N, U>
    ): Promise<OpenKeyPair<SecondaryKey<N, U>>> {
      const key = secondaryKeysCache[keyName]
      if (key) {
        if (key.name !== keyName) {
          throw new Error(`Invalid key name in cache: expected ${keyName}, got: ${key.name}`)
        }
        if (key.usage !== usage) {
          throw new Error(`Invalid key name in cache: expected ${usage}, got: ${key.usage}`)
        }

        return key as OpenKeyPair<SecondaryKey<N, U>>
      }

      const newKey = await fetchSecondaryKey(keyName, usage)

      secondaryKeysCache[keyName] = newKey
      return newKey
    },
    signPayload(keyName: KeyName, payload: string): Promise<string> {
      const key = secondaryKeysCache[keyName]
      if (!key) {
        throw new Error(`Tried to use unknown key: ${keyName}`)
      }
      if (!key.keyPair.privateKey.usages.includes('sign')) {
        throw new Error('Tried to use a key that is not usable for signing. This is a Zerauth bug.')
      }

      return signWithPrivateKey(key.keyPair.privateKey, payload)
    },
    extractNurneKey(keyName: `nurne_${string}`): OpenKeyPair<NurneKey> | null {
      return (secondaryKeysCache[keyName] as OpenKeyPair<NurneKey>) || null
    },
  }
}
