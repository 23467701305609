
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export type LabelAlignment = 'left' | 'center' | 'right'
export type LabelColor = 'light' | 'normal' | 'white' | 'primary' | 'success'
export type LabelSize = 'xsmall' | 'small' | 'normal' | 'large' | 'xlarge' | 'xxlarge'
export type LabelSpacing = 'tighter' | 'tight' | 'normal' | 'wide' | 'wider' | 'widest'
export type LabelWeight =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'

@Component
export default class NuLabel extends Vue {
  @Prop({ type: String, default: 'small' }) readonly size!: LabelSize
  @Prop({ type: String, default: 'medium' }) readonly weight!: LabelWeight
  @Prop({ type: String, default: 'normal' }) readonly color!: LabelColor
  @Prop({ type: String, default: 'normal' }) readonly spacing!: LabelSpacing
  @Prop({ type: String, default: 'Left' }) readonly align!: LabelAlignment
  @Prop({ type: Boolean, default: false }) readonly uppercase!: boolean
  @Prop({ type: Boolean, default: false }) readonly italic!: boolean
  @Prop({ type: Boolean, default: false }) readonly keepWhitespace!: boolean

  public $refs!: { labelRef: HTMLDivElement }

  get textSize(): string {
    switch (this.size) {
      case 'xsmall':
        return 'text-xs'
      case 'small':
        return 'text-sm'
      case 'normal':
        return 'text-base'
      case 'large':
        return 'text-lg'
      case 'xlarge':
        return 'text-xl'
      case 'xxlarge':
        return 'text-2xl'
    }
  }

  get textWeight(): string {
    switch (this.weight) {
      case 'thin':
        return 'font-thin'
      case 'extralight':
        return 'font-extralight'
      case 'light':
        return 'font-light'
      case 'normal':
        return 'font-normal'
      case 'medium':
        return 'font-medium'
      case 'semibold':
        return 'font-semibold'
      case 'bold':
        return 'font-bold'
      case 'extrabold':
        return 'font-extrabold'
    }
  }

  get textSpacing(): string {
    switch (this.spacing) {
      case 'tighter':
        return 'tracking-tighter'
      case 'tight':
        return 'tracking-tight'
      case 'normal':
        return 'tracking-normal'
      case 'wide':
        return 'tracking-wide'
      case 'wider':
        return 'tracking-wider'
      case 'widest':
        return 'tracking-widest'
    }
  }

  get textColor(): string {
    switch (this.color) {
      case 'normal':
        return 'text-black'
      case 'light':
        return 'text-lighten'
      case 'white':
        return 'text-white'
      case 'primary':
        return 'text-primary-contrast'
      case 'success':
        return 'text-success-contrast'
    }
  }

  get textAlignment(): string {
    switch (this.align) {
      case 'left':
        return 'text-left'
      case 'center':
        return 'text-center'
      case 'right':
        return 'text-right'
    }
  }

  get textUppercase(): string {
    return this.uppercase ? 'uppercase' : ''
  }

  get textItalic(): string {
    return this.italic ? 'italic' : ''
  }

  get whitespace(): string {
    return this.keepWhitespace ? 'whitespace-pre-line' : ''
  }

  get classes(): string[] {
    return [
      this.textSize,
      this.textWeight,
      this.textSpacing,
      this.textColor,
      this.textUppercase,
      this.textItalic,
      this.textAlignment,
      this.whitespace,
    ]
  }
}
