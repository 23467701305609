import { encode as bufferToB64 } from 'base64-arraybuffer'
import { hash } from './argon2'
import type {
  EncryptedKey,
  EncryptedKeyPair,
  EncryptedPayload,
  OpenKey,
  OpenKeyPair,
} from './key-types'

const AES_KEY_BYTE_LENGTH = 16

const subtleCrypto = window.crypto.subtle
const getRandomValues = window.crypto.getRandomValues.bind(window.crypto)
const stringToBuffer = new TextEncoder().encode.bind(new TextEncoder())
const bufferToString = new TextDecoder().decode.bind(new TextDecoder())

export function generateSalt(): ArrayBuffer {
  return getRandomValues(new Uint8Array(16))
}

/**
 * Turns a password (with salt) into an AES-GCM 128 bit encryption/decryption key using Argon2 hashing.
 * @param password The password, in clear text as a string.
 * @param saltB64 The salt, as a base64-encoded string.
 */
export async function derivePassword(password: string, saltB64: string): Promise<CryptoKey> {
  const hashedPassword = await hash(password, saltB64, AES_KEY_BYTE_LENGTH)

  return await subtleCrypto.importKey(
    'raw',
    hashedPassword,
    { name: 'AES-GCM', length: AES_KEY_BYTE_LENGTH },
    false,
    ['encrypt', 'decrypt']
  )
}

export function generateNewMasterKey(): Promise<CryptoKey> {
  return subtleCrypto.generateKey({ name: 'AES-GCM', length: AES_KEY_BYTE_LENGTH * 8 }, true, [
    'encrypt',
    'decrypt',
  ])
}

export function generateNewSecondaryKeyPair(): Promise<CryptoKeyPair> {
  return subtleCrypto.generateKey({ name: 'ECDSA', namedCurve: 'P-256' }, true, ['sign'])
}

export function exportToJwk(key: CryptoKey): Promise<JsonWebKey> {
  return subtleCrypto.exportKey('jwk', key)
}

export async function encryptKeyPair<T extends object>(
  openKey: OpenKeyPair<T>,
  encryptionKey: CryptoKey
): Promise<EncryptedKeyPair<Omit<T, keyof OpenKeyPair>>> {
  const { keyPair, ...keyData } = openKey

  const payload = await encryptPayload(
    await subtleCrypto.exportKey('jwk', keyPair.privateKey),
    encryptionKey
  )
  const publicKey = await subtleCrypto.exportKey('spki', keyPair.publicKey)

  return { publicKey, ...payload, ...keyData }
}

export async function encryptKey<T extends object>(
  openKey: OpenKey<T>,
  encryptionKey: CryptoKey
): Promise<EncryptedKey<Omit<T, keyof OpenKey>>> {
  const { key, ...keyData } = openKey

  const payload = await encryptPayload(await subtleCrypto.exportKey('jwk', key), encryptionKey)

  return { ...payload, ...keyData }
}

type KeyUsage = 'sign' | 'encrypt' | 'nurne'

export async function decryptKeyPair<T extends object>(
  encryptedKey: EncryptedKeyPair<T>,
  decryptionKey: CryptoKey,
  usage: KeyUsage,
  extractable = false
): Promise<OpenKeyPair<Omit<T, keyof EncryptedKeyPair<{}>>>> {
  const privateKey = await decryptKey(encryptedKey, decryptionKey, usage, extractable)
  const { key, publicKey, ...keyData } = privateKey

  let decryptedPublicKey
  switch (usage) {
    case 'sign':
      decryptedPublicKey = await subtleCrypto.importKey(
        'spki',
        publicKey,
        { name: 'ECDSA', namedCurve: 'P-256' },
        extractable,
        []
      )
      break
    case 'encrypt':
      decryptedPublicKey = await subtleCrypto.importKey(
        'spki',
        publicKey,
        'AES-GCM',
        extractable,
        []
      )
      break
    case 'nurne':
      decryptedPublicKey = await subtleCrypto.importKey(
        'spki',
        publicKey,
        { name: 'ECDH', namedCurve: 'P-256' },
        extractable,
        []
      )
  }

  return {
    keyPair: { publicKey: decryptedPublicKey, privateKey: key },
    ...keyData,
  } as OpenKeyPair<Omit<T, keyof EncryptedKeyPair<{}>>>
}

export async function decryptKey<T extends object>(
  encryptedKey: EncryptedKey<T>,
  decryptionKey: CryptoKey,
  usage: KeyUsage,
  extractable = false
): Promise<OpenKey<Omit<T, keyof EncryptedKey>>> {
  const { encryptedContents, iv, ...keyData } = encryptedKey
  const decryptedJwk = await subtleCrypto.decrypt(
    { name: 'AES-GCM', iv },
    decryptionKey,
    encryptedContents
  )
  const jwk = JSON.parse(bufferToString(decryptedJwk))

  let cryptoKey
  switch (usage) {
    case 'sign':
      cryptoKey = await subtleCrypto.importKey(
        'jwk',
        jwk,
        { name: 'ECDSA', namedCurve: 'P-256' },
        extractable,
        ['sign']
      )
      break
    case 'encrypt':
      cryptoKey = await subtleCrypto.importKey('jwk', jwk, 'AES-GCM', extractable, [
        'encrypt',
        'decrypt',
      ])
      break
    case 'nurne':
      cryptoKey = await subtleCrypto.importKey(
        'jwk',
        jwk,
        { name: 'ECDH', namedCurve: 'P-256' },
        extractable,
        ['deriveBits']
      )
  }

  return { key: cryptoKey, ...keyData }
}

export async function signWithPrivateKey(privateKey: CryptoKey, payload: string): Promise<string> {
  const toSign = stringToBuffer(payload)

  return bufferToB64(
    await subtleCrypto.sign({ name: 'ECDSA', hash: 'SHA-256' }, privateKey, toSign)
  )
}

// Internal stuff
async function encryptPayload(
  toEncrypt: object,
  encryptionKey: CryptoKey
): Promise<EncryptedPayload> {
  const iv = getRandomValues(new Uint8Array(12))
  const payload = stringToBuffer(JSON.stringify(toEncrypt, null, 0))
  const encrypted = await subtleCrypto.encrypt({ name: 'AES-GCM', iv }, encryptionKey, payload)

  return {
    iv,
    encryptedContents: encrypted,
  }
}

// From subtleCrypto.digest

export type AcceptedBuffers =
  | Int8Array
  | Int16Array
  | Int32Array
  | Uint8Array
  | Uint16Array
  | Uint32Array
  | Uint8ClampedArray
  | Float32Array
  | Float64Array
  | DataView
  | ArrayBuffer

export async function sha256Hash(content: string | AcceptedBuffers): Promise<string> {
  return bufferToB64(
    await subtleCrypto.digest(
      'SHA-256',
      typeof content === 'string' ? stringToBuffer(content) : content
    )
  )
}
