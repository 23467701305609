/**
 * This module is a thin wrapper to help manage LocalStorage, if available
 */

/**
 * Checks if the given Storage feature is present and available (i.e. not disabled by browser). From
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 */
function storageAvailable(): boolean {
  const storage = localStorage
  if (!storage) {
    return false
  }

  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    if (storage.getItem(x) !== x) {
      return false
    }
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    )
  }
}

/**
 * If the local storage feature is available or not.
 */
const LOCAL_STORAGE_AVAILABLE = storageAvailable()
const LOCAL_STORAGE_PREFIX = 'thesaurio/'

/**
 * Retrieves an item from the browser's local storage.
 * If the key does not exist, or if local storage is not available, the given defaultValue is returned.
 */
export function get(key: string): string | null {
  return getUnprefixed(LOCAL_STORAGE_PREFIX + key)
}

/**
 * Retrieves an item from the browser's local storage.
 * If the key does not exist, or if local storage is not available, the given defaultValue is returned.
 * Unlike get(), this function does not add the default thesaurio/ prefix to the local storage item
 */
export function getUnprefixed(key: string): string | null {
  if (!LOCAL_STORAGE_AVAILABLE) {
    return null
  }
  return localStorage.getItem(key)
}

/**
 * Puts an item into the browser's LocalStorage.
 * This method fails silently if local storage is not available, or if the setItem() method failed.
 */
export function set(key: string, value: string): void {
  if (!LOCAL_STORAGE_AVAILABLE) {
    return
  }
  try {
    localStorage.setItem(LOCAL_STORAGE_PREFIX + key, value || '')
  } catch (e) {
    //
  }
}

/**
 * Puts or deletes an item into the browser's LocalStorage.
 * @param key The key to insert or remove
 * @param value The value to insert. If null or undefined, it is removed instead
 */
export function setOrClear(key: string, value?: string | null): void {
  if (!value) {
    return remove(key)
  }
  set(key, value)
}

/**
 * Removes an item from the browser's LocalStorage.
 * This method fails silently if local storage is not available, or if the setItem() method failed.
 */
export function remove(key: string): void {
  if (!LOCAL_STORAGE_AVAILABLE) {
    return
  }
  try {
    localStorage.removeItem(LOCAL_STORAGE_PREFIX + key)
  } catch (e) {
    //
  }
}
