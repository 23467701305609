
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { nuagPrivateClient } from '~/graphql/clients'

const EXCLUDED_PATHS_PREFIXES = [
  'organizer-edit-id',
  'organizer-edit-cse-id',
  'organizer-new-id',
  'live-id',
  'hsbc-id-live',
  'participant-convocations-join',
  'participant-assembly-id-closed',
  'participant-id-sms-check',
  'cse',
  'home',
]

@Component
export default class AppContainer extends Vue {
  @Prop(Boolean) readonly forceUnfold!: boolean
  public ongoingAssemblySecret: string | null | undefined = null

  disableForceUnfold() {
    this.$emit('update:forceUnfold', false)
  }

  dimmerFoldVariables() {
    return (this.forceUnfold ? ['visible', 'opacity-1'] : ['invisible', 'opacity-0']).concat(
      this.appFoldVariable()
    )
  }

  appFoldVariable() {
    return this.forceUnfold ? ['translate-x-layout-xl', 'md:translate-x-0'] : []
  }

  public clickedBanner() {
    this.$router.push({
      name: 'participant-convocations-join',
      query: { n2: this.ongoingAssemblySecret! },
    })
  }

  public async mounted() {
    this.ongoingAssemblySecret = (
      await nuagPrivateClient.fetchOngoingAssemblySecret()
    ).ongoingAssemblySecret
  }

  public get showBanner(): boolean {
    return (
      !!this.ongoingAssemblySecret &&
      !EXCLUDED_PATHS_PREFIXES.some((p) => this.$route.name?.startsWith(p))
    )
  }
}
