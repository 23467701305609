var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.iconOnly)?_c('Button',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"nuag-button iconOnly p-button-rounded",class:{
    'nuag-button__outline': _vm.outline,
    borderless: _vm.borderless,
    soft: _vm.soft,
    loading: _setup.currentlyLoading,
    disabled: _vm.disabled,
    ping: _vm.ping,
    locked: !!_vm.locked || !!_vm.deactivated,
    [_vm.color || 'primary']: true,
    small: _vm.small,
    toggled: _vm.toggle && !!_vm.value,
  },attrs:{"disabled":_vm.disabled || !!_vm.locked || _setup.currentlyLoading || !!_vm.deactivated,"type":_vm.type || 'button'}},_setup.events),[(_vm.ping)?_c('span',{staticClass:"ping"}):_vm._e(),_vm._v(" "),(_vm.to)?_c('nuxt-link',{staticClass:"tooltip-activator",attrs:{"to":_vm.to,"custom":""}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_setup.tooltip),expression:"tooltip",modifiers:{"top":true}}],staticClass:"tooltip-activator"}),_vm._v(" "),(_setup.currentlyLoading)?_c('nuag-icon',{staticClass:"before",attrs:{"icon":"loader-3"}}):(_vm.icon)?_c('nuag-icon',{staticClass:"before",attrs:{"icon":_vm.icon,"filled":_vm.iconFilled}}):_vm._e()],1):_c('Button',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"nuag-button",class:{
    'nuag-button__outline': _vm.outline,
    borderless: _vm.borderless,
    soft: _vm.soft,
    loading: _setup.currentlyLoading,
    disabled: _vm.disabled,
    ping: _vm.ping,
    locked: !!_vm.locked || !!_vm.deactivated,
    [_vm.color || 'primary']: true,
    small: _vm.small,
  },attrs:{"label":_vm.label,"disabled":_vm.disabled || !!_vm.locked || _setup.currentlyLoading || !!_vm.deactivated,"type":_vm.type || 'button'}},_setup.events),[(_vm.ping)?_c('span',{staticClass:"ping"}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_setup.tooltip),expression:"tooltip",modifiers:{"top":true}}],staticClass:"tooltip-activator"}),_vm._v(" "),_c('span',{staticClass:"p-button-label"},[(_setup.currentlyLoading)?_c('nuag-icon',{staticClass:"before",attrs:{"icon":"loader-3"}}):(_vm.locked)?_c('nuag-icon',{staticClass:"before",attrs:{"icon":"lock-2"}}):_vm._e(),_vm._v(" "),(_vm.icon && !_vm.locked)?_c('nuag-icon',{staticClass:"icon",attrs:{"icon":_vm.icon,"filled":_vm.iconFilled}}):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]}),_vm._v(" "),(_vm.go)?_c('nuag-icon',{staticClass:"after",attrs:{"icon":"arrow-right-1"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }