import { arrayOfAll } from './type-help'

export enum Color {
  Gray = 'gray',
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  Teal = 'teal',
  Blue = 'blue',
  Indigo = 'indigo',
  Purple = 'purple',
  Pink = 'pink',
}

export const allColors = arrayOfAll<Color>()([
  Color.Gray,
  Color.Red,
  Color.Orange,
  Color.Yellow,
  Color.Green,
  Color.Teal,
  Color.Blue,
  Color.Indigo,
  Color.Purple,
  Color.Pink,
])

/**
 * Returns the hex representation of a color
 * @param c Color to hexify
 * @example colorToHex(Color.Gray) // #718096
 * @example colorToHex(Color.Blue) // ##3182ce
 */
export function colorToHex(c: Color): string {
  switch (c) {
    case Color.Gray:
      return '#718096'
    case Color.Red:
      return '#e53e3e'
    case Color.Orange:
      return '#dd6b20'
    case Color.Yellow:
      return '#d69e2e'
    case Color.Green:
      return '#38a169'
    case Color.Teal:
      return '#319795'
    case Color.Blue:
      return '#3182ce'
    case Color.Indigo:
      return '#5a67d8'
    case Color.Purple:
      return '#805ad5'
    case Color.Pink:
      return '#d53f8c'
  }
}
