
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export type AlertColor = 'information' | 'success' | 'warning' | 'critical'

@Component
export default class NuAlert extends Vue {
  @Prop(String) readonly color!: AlertColor
  @Prop(String) readonly title!: string
  @Prop(Boolean) readonly fat!: boolean
  @Prop(Function) readonly action?: () => void

  get hasDescription(): boolean {
    return !!this.$slots.default
  }

  get classes(): string[] {
    return [...this.colorClasses, ...this.paddingClasses, ...this.pointerClasses]
  }

  get colorClasses(): string[] {
    switch (this.color) {
      case 'information':
        return ['bg-information', 'border-information-darkened']
      case 'success':
        return ['bg-success', 'border-success-darkened']
      case 'warning':
        return ['bg-warning', 'border-warning-darkened']
      case 'critical':
        return ['bg-critical', 'border-critical-darkened']
    }
  }

  get paddingClasses(): string[] {
    if (!this.fat) {
      return ['px-sm', 'py-xs']
    } else {
      return ['px-xl', 'py-md']
    }
  }

  get pointerClasses(): string[] {
    if (this.action) {
      return ['cursor-pointer']
    } else {
      return []
    }
  }

  public handleClick() {
    if (this.action) {
      this.action()
    }
  }
}
