import { render, staticRenderFns } from "./default.vue?vue&type=template&id=88dc0054"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./default.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuAvatar: require('/home/vsts/work/1/s/components/nu/avatar.vue').default,CommonSidebarButton: require('/home/vsts/work/1/s/components/common/sidebar-button.vue').default,CommonSidebarSection: require('/home/vsts/work/1/s/components/common/sidebar-section.vue').default,PageLayoutSidebar: require('/home/vsts/work/1/s/components/page-layout/sidebar.vue').default,CommonNotifications: require('/home/vsts/work/1/s/components/common/notifications.vue').default,CommonModalContainer: require('/home/vsts/work/1/s/components/common/modal-container.vue').default})
