
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import type { Location } from 'vue-router'

export type ButtonColors = 'primary' | 'success' | 'warning' | 'critical' | 'neutral'
export function getCssForColor(color: ButtonColors): string[] {
  switch (color) {
    case 'primary':
      return [
        'bg-primary',
        'active:bg-primary-darkened',
        'hover:bg-primary-highlighted',
        'text-primary-contrast',
        'disabled:bg-primary-highlighted',
        'disabled:text-primary-contrast',
        'focus:ring-primary-highlighted',
        'border-primary-darkened',
      ]
    case 'success':
      return [
        'bg-success',
        'active:bg-success-darkened',
        'hover:bg-success-highlighted',
        'text-success-contrast',
        'disabled:bg-success-highlighted',
        'disabled:text-success-contrast',
        'focus:ring-success-highlighted',
        'border-success-darkened',
      ]
    case 'warning':
      return [
        'bg-warning',
        'active:bg-warning-darkened',
        'hover:bg-warning-highlighted',
        'text-warning-contrast',
        'disabled:bg-warning-highlighted',
        'disabled:text-warning-contrast',
        'focus:ring-warning-highlighted',
        'border-warning-darkened',
      ]
    case 'critical':
      return [
        'bg-critical',
        'active:bg-critical-darkened',
        'hover:bg-critical-highlighted',
        'text-critical-contrast',
        'disabled:bg-critical-highlighted',
        'disabled:text-critical-contrast',
        'focus:ring-critical-highlighted',
        'border-critical-darkened',
      ]
    case 'neutral':
      return [
        'bg-neutral',
        'active:bg-neutral-darkened',
        'hover:bg-neutral-highlighted',
        'text-neutral-contrast',
        'disabled:bg-neutral-highlighted',
        'disabled:text-neutral-contrast',
        'focus:ring-neutral-highlighted',
        'border-neutral-darkened',
      ]
  }
  return []
}

@Component
export default class NuButton extends Vue {
  @Prop(String) readonly title?: string
  @Prop(String) readonly icon?: string
  @Prop({ type: String, default: 'neutral' }) readonly color!: ButtonColors
  @Prop({ type: Boolean, default: false }) readonly fullWidth!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  @Prop({ type: Boolean, default: false }) readonly submit!: boolean
  @Prop({ type: Boolean, default: false }) readonly ping!: boolean
  @Prop({ type: Boolean, default: false }) readonly fat!: boolean
  @Prop([Object, String]) readonly to?: Location

  get isDisabled(): boolean {
    return this.disabled || this.loading
  }

  get cssClasses(): string[] {
    return getCssForColor(this.color).concat([
      this.fullWidth ? 'w-full' : '',
      this.fat ? 'py-md' : 'py-xs',
    ])
  }

  buttonClicked(args: MouseEvent) {
    this.$emit('click', args)
  }
}
