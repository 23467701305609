import { getCurrentScope } from 'vue';
function watchMediaQuery(mediaQuery) {
  var mediaQueryList = window.matchMedia(mediaQuery);
  var matches = ref(mediaQueryList.matches);
  function changed(e) {
    matches.value = e.matches;
  }
  mediaQueryList.addEventListener('change', changed);
  if (getCurrentScope()) {
    onBeforeUnmount(function () {
      return mediaQueryList.removeEventListener('change', changed);
    });
  }
  return readonly(matches);
}
export function useResponsive() {
  return {
    isLargeScreen: watchMediaQuery('(min-width: 1100px)')
  };
}