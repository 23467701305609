
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import type { Location } from 'vue-router'

export type HyperlinkSize = 'xsmall' | 'small' | 'medium' | 'large'

@Component
export default class NuHyperlink extends Vue {
  @Prop(String) readonly title!: string
  @Prop([Object, String]) readonly to?: Location
  @Prop({ type: String, default: 'small' }) readonly size!: HyperlinkSize
  @Prop({ type: Boolean, default: false }) readonly inline!: boolean
  @Prop({ type: Boolean, default: false }) readonly white!: boolean

  get cssClass(): string {
    switch (this.size) {
      case 'xsmall':
        return 'text-xs'
      case 'small':
        return 'text-sm'
      case 'medium':
        return 'text-md'
      case 'large':
        return 'text-lg'
    }
  }

  get textColor(): string {
    if (this.white) {
      return 'text-white'
    }
    return 'text-black'
  }

  get textHover(): string {
    if (this.white) {
      return 'hover:text-white'
    }
    return 'hover:text-primary-contrast'
  }

  linkClicked(args: MouseEvent) {
    this.$emit('click', args)
  }
}
