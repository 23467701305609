
import { Vue, Component } from 'nuxt-property-decorator'
import { sessionInfo } from '~/lib/zerauth'

@Component
export default class DefaultLayout extends Vue {
  get userId() {
    return sessionInfo.me?.id
  }

  get userFirstName() {
    return sessionInfo.me?.firstName || ''
  }

  get userFullName() {
    if (!sessionInfo.me) {
      return ''
    }
    return sessionInfo.me.firstName + ' ' + sessionInfo.me.lastName
  }
}
