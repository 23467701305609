import type { Context } from '@nuxt/types'
import * as LocalStorage from '~/lib/localstorage'

let ranOnce = false

async function getMaintenanceStatus(): Promise<boolean> {
  try {
    const resp = await fetch('/nuag-maintenance')
    return (await resp.text()).trim() === 'MAINTENANCE: YES'
  } catch (e) {
    return false
  }
}

export default async function (context: Context) {
  if (ranOnce) {
    return
  }

  const isInMaintenance = await getMaintenanceStatus()
  const hasBypass = LocalStorage.getUnprefixed('NAKA_BLESSED') === 'true'

  ranOnce = true

  if (isInMaintenance && !hasBypass) {
    context.error({
      statusCode: 1999,
    })
  }
}
