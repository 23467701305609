
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class TopBar extends Vue {
  @Prop(Boolean) readonly forceUnfold!: boolean

  toggleForceUnfold() {
    this.$emit('update:forceUnfold', !this.forceUnfold)
  }
}
