import { b64ToUrlB64, encodeUrlB64 } from '~/lib/zerauth/utils/url-base64'
import { SecondaryKeyUsage } from '~/graphql/types/accounts-public'
import { fetchCorrectedTimestamp } from '~/lib/zerauth/utils/remote-time'

export type KeyName = 'accounts' | 'accounts_tur' | 'nuag_tur' | `nurne_${string}`

export const keyUsages: Record<KeyName, SecondaryKeyUsage> = {
  accounts: SecondaryKeyUsage.Signature,
  accounts_tur: SecondaryKeyUsage.Turones,
  nuag_tur: SecondaryKeyUsage.Turones,
}

type PayloadSigner = (input: string) => Promise<string>

export async function craftSignedPayload<T extends object>(
  emitterId: string,
  data: T,
  signer: PayloadSigner
): Promise<string> {
  const timestamp = await fetchCorrectedTimestamp()

  const rawPayload = {
    ...data,
    timestamp,
    emitter_uuid: emitterId,
  }

  const header = encodeUrlB64(JSON.stringify({ alg: 'ES256' }))
  const content = encodeUrlB64(JSON.stringify(rawPayload))

  const input = header + '.' + content

  const signature = await signer(input)

  return input + '.' + b64ToUrlB64(signature)
}
