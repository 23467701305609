import { SecondaryKeyUsage } from '~/graphql/types/accounts'
import type { KeyName } from '~/lib/zerauth/signature'

/**
 * The 'version' of keys that this library may use.
 * This may be useful in the future when we make breaking changes,and we may have to handle multiple
 * versions of stored keys.
 */
export const CURRENT_ALGORITHM_VERSION = 1

export interface EncryptedPayload {
  iv: ArrayBuffer
  encryptedContents: ArrayBuffer
}

export type EncryptedKey<T = {}> = T & EncryptedPayload

export type EncryptedKeyPair<T> = EncryptedKey<T> & {
  publicKey: ArrayBuffer
}

export type OpenKey<T = {}> = T & {
  key: CryptoKey
}

export type OpenKeyPair<T = {}> = T & {
  keyPair: CryptoKeyPair
}

export interface MasterKey {
  saltB64: string
  version: number
}

export interface SecondaryKey<
  N extends KeyName = KeyName,
  U extends SecondaryKeyUsage = SecondaryKeyUsage,
> {
  name: N
  usage: U
}

export interface NewSecondaryKey<
  N extends KeyName = KeyName,
  U extends SecondaryKeyUsage = SecondaryKeyUsage,
> extends SecondaryKey<N, U> {
  id: string
  version: number
}

export type UnvalidatedSessionKey = NewSecondaryKey<'accounts', SecondaryKeyUsage.Signature>

export interface SessionKey extends SecondaryKey<'accounts', SecondaryKeyUsage.Signature> {
  sessionKey: string
}

/**
 * The Session Keys, usually loaded from the Keystore, and used to craft TGTs to ask for new Session Tokens.
 */
export interface SessionKeys {
  masterKey: OpenKey<MasterKey>
  sessionKey: OpenKey<SessionKey>
  encryptedSessionKey: EncryptedKey<SessionKey>
}
