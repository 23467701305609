import type { ComputedRef, InjectionKey } from 'vue'

export interface ITaskInput {
  completed: ComputedRef<boolean>
  completable: ComputedRef<boolean>
}

export interface ITaskOutput {
  id: number
  displayStep: ComputedRef<number>
  isFirstToDo: ComputedRef<boolean>
}

export interface ITrackerInjection {
  register(input: ITaskInput): ITaskOutput
  unregister(id: number): void

  readonly isTest: boolean
}

export interface ISubtaskInput {
  completed: ComputedRef<boolean>
}

export interface ISubtaskOutput {
  id: number
}

export interface ITrackerTaskInjection {
  register(input: ISubtaskInput): ISubtaskOutput
  unregister(id: number): void
}

export const trackerInjectionKey = Symbol('trackerInjectionKey') as InjectionKey<ITrackerInjection>
export const trackerTaskInjectionKey = Symbol(
  'trackerTaskInjectionKey'
) as InjectionKey<ITrackerTaskInjection>
