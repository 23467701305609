import { inject, nextTick, onBeforeUnmount, onMounted, ref, reactive } from 'vue';
import { formInjectionKey } from './form-mixins';
export function useValidation(options) {
  var invalid = ref(false);
  var errorMessage = ref('');
  function resetValidation() {
    invalid.value = false;
    errorMessage.value = '';
  }
  function updateValidation() {
    resetValidation();
    var empty = options.isEmpty;
    if (options.props.required && empty) {
      invalid.value = true;
      errorMessage.value = typeof options.props.required === 'string' ? options.props.required : 'Veuillez remplir ce champ.'; // TODO: i18n
      return;
    }
    if (typeof options.props.rules !== 'undefined' && !empty && options.props.value) {
      var rules = Array.isArray(options.props.rules) ? options.props.rules : [options.props.rules];
      for (var i = 0; i < rules.length; i++) {
        var rule = rules[i];
        var result = typeof rule === 'boolean' ? rule : rule && rule(options.props.value);
        if (!result) {
          var ruleMessage = Array.isArray(options.props.ruleMessages) ? options.props.ruleMessages[i] : options.props.ruleMessages;
          invalid.value = true;
          errorMessage.value = ruleMessage || 'Ce champ est invalide.'; // TODO: i18n, should trigger warning
          break;
        }
      }
    }
  }
  function newInput() {
    var forceValidation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (forceValidation || invalid.value) {
      nextTick(function () {
        return updateValidation();
      });
    }
  }
  function blurred() {
    nextTick(function () {
      return updateValidation();
    });
  }
  var input = reactive({
    invalid: invalid,
    errorMessage: errorMessage,
    resetValidation: resetValidation,
    updateValidation: updateValidation,
    newInput: newInput,
    blurred: blurred
  });
  watch(function () {
    return options.props.required;
  }, function () {
    return input.updateValidation();
  });
  var form = inject(formInjectionKey, null);
  if (form) {
    onMounted(function () {
      return form.registerField(input);
    });
    onBeforeUnmount(function () {
      return form.unregisterField(input);
    });
  }
  return input;
}