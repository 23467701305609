
import type { NuxtError } from '@nuxt/types'
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { hideDefaultCrispLauncher } from 'nuag-core-utils/crisp'

const BROWSER_HELP_LINK =
  'https://help.nuag.fr/fr/article/pourquoi-mon-navigateur-nest-il-pas-compatible-avec-nuag-yt6rrf/'

@Component({ layout: 'welcome' })
export default class Error extends Vue {
  @Prop() error!: NuxtError

  get errorKey(): string {
    if (this.is404) {
      return 'notFound'
    } else if (this.isMaintenance) {
      return 'maintenance'
    } else if (this.isCrypto) {
      return 'crypto'
    } else {
      return 'default'
    }
  }

  mounted(): void {
    hideDefaultCrispLauncher(false)
  }

  get is404(): boolean {
    return this.error.statusCode === 404
  }

  get isMaintenance(): boolean {
    return this.error.statusCode === 1999
  }

  get isCrypto(): boolean {
    return this.error.statusCode === 1998
  }

  public openExternalBrowserHelp(): void {
    window.open(BROWSER_HELP_LINK)
  }
}
