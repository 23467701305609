import * as Sentry from '@sentry/vue'

Sentry.addGlobalEventProcessor((event, hint) => {
  if (hint.originalException instanceof TypeError) {
    if (
      hint.originalException.message === 'Failed to fetch' ||
      hint.originalException.message === 'Network request failed' ||
      hint.originalException.name === 'NetworkError' ||
      hint.originalException.message === 'NetworkError when attempting to fetch resource.'
    ) {
      return null
    }
  }

  return event
})
