import { ref, onMounted, type Ref, onBeforeUnmount } from 'vue'
import Emojis from '../../assets/emojis'

export type INotificationOptions = {
  color: 'error' | 'info' | 'neutral' | 'primary' | 'success'
  emoji: keyof typeof Emojis
  title: string
  details: string
  delay?: number
} & ({ delay?: number; persistent?: false } | { persistent: true })

export function notify(notification: INotificationOptions): void {
  notifiers.forEach((n) => n(notification))
}

// Notifier

type NotifierInternal = typeof notify
const notifiers: NotifierInternal[] = []

export type INotification = INotificationOptions & {
  id: number
  close(): void
  inhibit(): void
  resume(): void
}

export interface INotifier {
  readonly activeNotifications: Readonly<Ref<INotification[]>>
}

export function useNotifier(): INotifier {
  let notificationId = 0
  const activeNotifications = ref<INotification[]>([])

  const notifier = function (notification: INotificationOptions) {
    const id = notificationId++
    let timeout: NodeJS.Timeout | null = null
    resume()

    function close() {
      inhibit()

      const notificationIndex = activeNotifications.value.findIndex((n) => n.id === id)
      if (notificationIndex >= 0) {
        activeNotifications.value.splice(notificationIndex, 1)
      }
    }

    function inhibit() {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    }

    function resume() {
      if (notification.persistent) {
        return
      }

      inhibit()
      timeout = setTimeout(close, notification.delay ?? 6000)
    }

    activeNotifications.value.push({ id, close, inhibit, resume, ...notification })
  }

  onMounted(() => {
    notifiers.push(notifier)
  })
  onBeforeUnmount(() => {
    const index = notifiers.indexOf(notifier)
    if (index >= 0) {
      notifiers.splice(index, 1)
    }
  })

  return { activeNotifications }
}
