// From https://stackoverflow.com/a/2117523/4174897 by broofa
export default function uuidV4(): string {
  // tslint:disable-next-line: ban-ts-ignore
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c) =>
      // tslint:disable-next-line: no-bitwise
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0]! & (15 >> (c / 4)))).toString(16) // wotan-disable-line ban-dom-globals
  )
}
