
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export type CardPadding = 'none' | 'small' | 'medium' | 'large'

@Component
export default class NuCard extends Vue {
  @Prop(String) readonly title?: string
  @Prop({ type: String, default: 'medium' }) readonly padding!: CardPadding
  @Prop({ type: Boolean, default: false }) readonly titleCentered!: boolean
  @Prop({ type: Boolean, default: false }) readonly titleUppercase!: boolean
  @Prop({ type: Boolean, default: false }) readonly grow!: boolean
  @Prop({ type: String }) readonly addedClass: string | undefined

  get paddingClass(): string {
    switch (this.padding) {
      case 'small':
        return 'p-sm'
      case 'medium':
        return 'p-md'
      case 'large':
        return 'p-lg'
      case 'none':
        return 'p-0'
    }
  }

  get cardClasses(): string[] {
    const classes = [this.paddingClass].concat(this.grow ? ['w-full'] : [])

    if (this.addedClass) {
      return classes.concat(this.addedClass)
    }

    return classes
  }

  get titleContainerClasses(): string[] {
    return [this.titleTextCentered, this.titleUppercase ? 'uppercase' : '']
  }

  get hasTitle(): boolean {
    return !!this.title
  }

  get titleTextCentered(): string {
    return this.titleCentered ? 'justify-center' : 'justify-start'
  }
}
