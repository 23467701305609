import type { Context } from '@nuxt/types'
import type { Route } from 'vue-router'
import Vue from 'vue'

export interface IModalGuardStatus {
  triggered: boolean
  blockedRoute: Route | null
}

let activeModalGuards = 0
export const modalGuardStatus: IModalGuardStatus = Vue.observable({
  triggered: false,
  blockedRoute: null,
})

export function activate() {
  activeModalGuards += 1
}

export function deactivate() {
  activeModalGuards -= 1
}

export default function (context: Context) {
  if (context.route.params.__overrideGuard) {
    modalGuardStatus.triggered = false
    return
  }

  if (activeModalGuards > 0) {
    modalGuardStatus.triggered = true
    modalGuardStatus.blockedRoute = Object.freeze(context.route)
    context.redirect({ name: context.from.name!, params: context.from.params, replace: true })
    return
  }

  modalGuardStatus.blockedRoute = null
}

window.addEventListener('beforeunload', (e) => {
  if (activeModalGuards > 0) {
    e.returnValue = 'Vous avez des modifications non enregistrées.'
    e.preventDefault()
  }
})
